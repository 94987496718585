<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="comp_b_imagem" :src="`${base_url}upload/company_branch/g/${comp_b_imagem}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Filiais</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="primary" dark class="mb-1" @click="loadCompanyBranches">
                <v-icon dark class="mdi mdi-sync"></v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'branch.add'}">
                <v-icon class="mdi mdi-plus"></v-icon> Adicionar Nova Filial
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="branch" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.comp_b_imagem`]="{ item }">
                <td width="15%" v-if="item.comp_b_imagem"><img :src="`${base_url}upload/company_branch/p/${item.comp_b_imagem}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item)"></td>
                <td width="15%" v-if="!item.comp_b_imagem"><img :src="`${base_url}upload/logo.png`" class="avatar" @click="ampliarFoto(item.comp_b_imagem)"></td>
            </template>

            <template v-slot:[`item.comp_b_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.comp_b_status" @change="onChangeEventHandler(item.comp_b_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editBranch(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteBranch(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailBranch(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
                
                <v-tooltip top color="teal lighten-1 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="teal lighten-1 lighten-1" @click.prevent="fotoBranch(item)" v-on="on">
                            <v-icon dark class="mdi mdi-image"></v-icon>
                        </v-btn>
                    </template>
                    <span>Fotos</span>
                </v-tooltip>
                
                <v-tooltip top color="orange darken-2 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="orange darken-2 lighten-1" @click.prevent="ampliarFoto(item)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";

export default {
    created() {
        this.loadCompanyBranches();
    },
    computed: {},
    name: "BranchesComponent",
    data() {
        return {
            comp_b_imagem: null,
            dialog_foto: false,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Imagem",
                    align: "left",
                    sortable: false,
                    value: "comp_b_imagem"
                },
                {
                    sortable: false,
                    text: "Titulo",
                    value: "comp_b_titulo"
                },
                {
                    sortable: false,
                    text: "Endereço",
                    value: "comp_b_endereco"
                },
                {
                    sortable: false,
                    text: "Número",
                    value: "comp_b_numero",
                    align: "center"
                },
                {
                    sortable: false,
                    text: "Bairro",
                    value: "comp_b_bairro"
                },
                {
                    sortable: false,
                    text: "Cidade",
                    value: "comp_b_cidade"
                },
                {
                    sortable: false,
                    text: "Estado",
                    value: "comp_b_estado"
                },
                {
                    sortable: false,
                    text: "CEP",
                    value: "comp_b_cep"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "comp_b_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            branch: [],
            base_url: URL_BASE
        };
    },
    methods: {
        ampliarFoto(foto) {
          console.log(foto.comp_b_imagem);
            this.comp_b_imagem = foto.comp_b_imagem;
            this.dialog_foto = true;
        },
        loadCompanyBranches() {
            this.$store
                .dispatch("loadCompanyBranches")
                .then(response => {
                    this.branch = response.data;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Erro",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        editBranch(item) {
            this.$router.push({
                name: "branch.edit",
                params: {
                    comp_b_id: item.comp_b_id
                }
            });
        },
        detailBranch(item) {
            this.$router.push({
                name: "branch.detail",
                params: {
                    comp_b_id: item.comp_b_id
                }
            });
        },
        fotoBranch(item) {
            this.$router.push({
                name: "branch.foto",
                params: {
                    comp_b_id: item.comp_b_id
                }
            });
        },
        deleteBranch(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyCompanyBranch", item.comp_b_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.loadBranches();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandler(comp_b_id, value) {
            this.$store
                .dispatch("updateCompanyBranchStatus", {
                    comp_b_id: comp_b_id,
                    comp_b_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Popup atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Popup",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
